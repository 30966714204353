.support{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
    position: relative;
}
.support-head{
    text-align: center;
    /* border: 1px solid red; */
}
.support-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
.s-card{
    width:250px;
    height: auto;
    text-align: center;
    line-height: 50px;
    color: rgb(31, 31, 31);
    /* border: 1px solid gray; */
    border-radius: 10px;
    background: white;
}
.s-card h6{
    font-size: 16px;
    font-weight: normal;
    color: gray;
}
.s-card h5{
    font-size: 16px;
    font-weight: normal;
    color: rgb(0, 0, 0);
}
.s-card h3{
    font-size: 30px;
    font-weight: normal;
    color: rgb(35, 35, 35);
}
.s-color{
    background: rgb(255, 218, 70);
    border-radius: 10px;
}
.s-back {
    position: absolute;
    background: rgb(236, 236, 236);
    width: 100%;
    height: 50%;
    bottom: 0;
    z-index: -1;
}