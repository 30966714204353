.team{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background: rgb(32, 32, 32);
}
.team-head{
    text-align: center;
    color: white;
   padding-top: 25px;
}
.team-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.team-card .team-img{
    position: relative;
    width: auto;
    height: auto;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.team-card .team-img img{
    width: 225px;
    height: 250px;
    border-radius: 10px;
}
.team-details{
    position: absolute;
    background: white;
    width: 200px;
    border-radius: 10px;
    bottom: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}
.team-details:hover{
    background: rgb(83, 172, 255);
}
.team-details:hover h3,h5{
    color: white;
}
.team-details h5{
    font-size: 15px;
    color: rgb(202, 202, 202);
    font-weight: lighter;
    text-transform: capitalize;
}
.team-details h3{
    color: rgb(59, 183, 255);
    text-transform: capitalize;
}
.team-social{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 10px;
}
.team-social i{
    color: rgb(183, 183, 183);
}
.team-view{
    margin-top:125px;
    text-align: center;
}
.team-view span{
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}