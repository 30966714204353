
.left-heading h2{
    font-size: 2rem;
    margin-bottom: 35px;
}
.left-heading h1{
    font-size: 3rem;
    font-weight: normal;
    margin-bottom: 20px;
    line-height: 50px;
    text-transform: capitalize;
    width: auto;
}
.left-heading p{
    margin-top: 20px;
    font-size: 1rem;
    color: gray;
    max-width: 500px;
}
.left-heading h2 span{
    padding: 5px;
    background: #000;
    color: #fff;
    border-radius: 10px 0 0 10px ;
}
