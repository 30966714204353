.content{
    margin-left: 25px;
    right: 25px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.left{
    position: relative;
    width: 50%;
    height: auto;
}
.card1{
    width: 450px;
    height: 200px;
    background: rgb(61, 61, 255);
    border-radius: 10px;
    position: absolute;
    left: 75px;
    top: 50px;
    z-index: 1;
}
.card2{
    width: 300px;
    height: 200px;
    background: rgb(185, 185, 185);
    border-radius: 10px;
    position: absolute;
    top: 300px;
    right: 50px;
    z-index: 1;
}
.card4{
    width: 250px;
    height: 100px;
    background:rgb(251, 219, 57);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    z-index: 1000;
    bottom: 25px;
    right: 125px;
}
.card4 .follow{
    font-size: 1.5rem;
    font-weight: bold;
}
.card4 i{
    margin: 10px;
    font-size: 1rem;
    cursor: pointer;
}
.card3{
    width: 450px;
    height: 450px;
    position:relative;
    z-index: 100;
}
.card3 img{
    width: 100%;
    height: 100%;
    z-index: 1000;
    border-radius: 10px;
}
.about-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100vh;
    /* border: 1px solid red; */
    margin-left: 20px;
    row-gap: 50px;
    position: relative;
    overflow: hidden;
}
.button{
    margin-top: 50px;
}
.background-path{
    position: absolute;
    bottom: 0px;
    right: -100px;
    rotate: 90deg;
    width: 200px;
    height: 200px;
}
.background-path img{
    width: 100%;
    height: 100%;
}