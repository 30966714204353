.comment-body{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid red;
    overflow: hidden;
    position: relative;
}
.comment-body i{
    border: 1px solid rebeccapurple;
    padding: 10px;
    border-radius: 50%;
}
.comment-body .left-arrow{
    position: absolute;
    left: 0;
}
.comment-body .right-arrow{
    position: absolute;
    right: 0;
}


.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
.lastSlide {
    transform: translateX(-100%);
  }
.nextSlide {
    transform: translateX(100%);
  }
  
 .comment-body .middle{
  
    position: absolute;
}
.content-img{
    width:100%;
    height: 300px;
    border: 1px solid rgb(255, 25, 0);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.content-img img{
    width: 300px;
    height: 300px;
}
.img1 img{
    width: 200px;
    height: 200px;
}
.img2 img{
    width: 200px;
    height: 200px;
}
.img1{
    left: -10%;
    bottom: 0;
}
.img2{
    right: -10%;
    bottom: 0;
}
.img1,.img2{
    position: absolute;
}