.client{
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.c-details{
    background:rgb(61, 61, 255);
    height: 200px;
    width: 90%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}
.c-details .c h1{
    font-size: 50px;
    font-weight: 500;
    color: rgb(255, 241, 115);
}
.c-details .c h2{
    font-size: 16px;
    font-weight: normal;
    color: rgb(220, 220, 220);
    text-transform: capitalize;
}
.c-details .c h3{
    font-size: 20px;
    font-weight: normal;
    color: white;
    text-transform: capitalize;
}