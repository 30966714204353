.process{
    height: 100vh;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
.top-p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    gap: 100px;
}
.top-p-head{
    width: 50%;
}
.top-p-detail{
    width: 500px;
    color: gray;
}
.bottom-p .bottom-left img{
    width: 400px;
    height: 400px;
    border-radius: 20px;
}
.bottom-p{
    width:75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    align-items: flex-start;
}
.bottom-p .bottom-left{
    position: relative;
}
.bottom-left .b-left-img{
    position: absolute;
    top: 0;
    width: 500px;
    height: 500px;
    z-index: -1;
}
.bottom-left .b-left-img img{
    width: 100%;
    height: 100%;
}
.b-right-card{
    border: 1px solid gray;
    width: 350px;
    margin-bottom: 25px;
   border-radius: 10px;
   display: flex;
   height: 125px;
   align-items: center;
   justify-content: center;
   gap: 50px;
   text-align: right;
}
.b-right-card h5{
    color: grey;
    font-weight: normal;
}
.b-right-card h3{
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-size: 25px;
}
.card-txt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: capitalize;
}
.card-img{
    max-width: 50px;
}
.card-img img{
    width: 100%;
    
}